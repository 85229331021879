import {Select} from 'bigdatr-style';
import {IconFlagAU, IconFlagNZ} from 'bigdatr-style/icon';
import {Flex} from 'bigdatr-style/layout';
import React from 'react';
import useFeatureFlags from '~/util/useFeatureFlags';
import {useCountry} from './CountryContext';

export enum SupportedCountry {
    AU = 'Australia',
    NZ = 'New Zealand'
}
export const AU = SupportedCountry.AU;
export const NZ = SupportedCountry.NZ;

export const countryLabels = {
    AU: 'Australia',
    NZ: 'New Zealand'
};

export default function CountrySwitch(props: {showText: boolean}) {
    const {country, setCountry} = useCountry();

    function label(input: {option: SupportedCountry; showText: boolean}) {
        const {showText} = input;
        switch (input.option) {
            case AU:
                return (
                    <Flex gap={2} alignItems="center">
                        <IconAustralia />
                        {showText && countryLabels.AU}
                    </Flex>
                );
            case NZ:
                return (
                    <Flex gap={2} alignItems="center">
                        <IconNewZealand />
                        {showText && countryLabels.NZ}
                    </Flex>
                );
        }
    }
    return (
        <Select
            transparent
            value={country}
            onChange={setCountry}
            options={[AU, NZ]}
            controlLabel={(option) => option && label({option, showText: props.showText})}
            label={(option) => option && label({option, showText: true})}
            hideCaret
        />
    );
}

//
// flag SVGs are taken from this figma plugin and optimized through SVGOMG
// icons: https://www.figma.com/community/file/1048528064393814860/country-flag-icons
// optimize SVGs with this before using in codebase https://jakearchibald.github.io/svgomg/
//

export function IconAustralia(props) {
    const {navigationV2} = useFeatureFlags();
    if (navigationV2)
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                width="24"
                viewBox="0 0 24 16"
                data-testid="flag-au"
                {...props}
            >
                <g clipPath="url(#a)">
                    <path fill="#10338C" d="M0 0h24v16H0V0Z" />
                    <path
                        fill="#fff"
                        d="m10.395 7.986.038-.042a.302.302 0 0 1-.038.042Zm-1.6 1.96.515 1.072 1.155-.267-.514 1.067.93.739-1.16.262.005 1.188-.93-.744-.927.744.005-1.188-1.16-.262.93-.74-.519-1.066 1.16.267.51-1.072Zm9.259 1.334.243.51.552-.127-.248.51.445.351-.552.122v.57l-.44-.355-.445.355.005-.57-.552-.122.444-.35-.247-.51.552.126.243-.51ZM15.78 5.853l.243.51.552-.127-.247.51.444.351-.552.126v.567l-.44-.356-.444.356.004-.567-.556-.126.444-.35-.248-.51.552.126.248-.51Zm2.274-3.097.243.51.552-.127-.248.51.445.351-.552.126v.566l-.44-.355-.445.355.005-.566L17.062 4l.444-.35-.247-.51.552.126.243-.51Zm1.988 2.325.243.51.552-.127-.247.51.444.351-.552.122v.57l-.44-.355-.444.356v-.571l-.552-.122.444-.35-.248-.51.552.126.248-.51ZM18.62 7.79l.192.594h.622l-.505.365.196.594-.505-.37-.505.37.191-.594-.5-.365h.622l.192-.594Z"
                    />
                    <path
                        fill="#fff"
                        d="M11.92 0v1.432L9.81 2.606h2.11V5.38H9.157l2.764 1.535v1.071h-1.249L7.233 6.073v1.913H4.632V5.712L.542 7.986h-.598V6.554L2.058 5.38H-.056V2.606h2.765l-2.765-1.54V0h1.249l3.439 1.909V0h2.6v2.274L11.327 0h.595Z"
                    />
                    <path
                        fill="#D80027"
                        d="M6.68 0H5.185v3.242h-5.24V4.74h5.24v3.247H6.68V4.739h5.24V3.242H6.68V0Z"
                    />
                    <path fill="#0052B4" d="m7.233 5.38 4.688 2.606v-.74L8.56 5.38H7.233Z" />
                    <path fill="#fff" d="m7.233 5.38 4.688 2.606v-.74L8.56 5.38H7.233Z" />
                    <path
                        fill="#D80027"
                        d="m7.233 5.38 4.688 2.606v-.74L8.56 5.38H7.233Zm-3.925 0L-.056 7.247v.739L4.632 5.38H3.308Z"
                    />
                    <path fill="#0052B4" d="M4.632 2.606-.056 0v.735l3.364 1.87h1.324Z" />
                    <path fill="#fff" d="M4.632 2.606-.056 0v.735l3.364 1.87h1.324Z" />
                    <path
                        fill="#D80027"
                        d="M4.632 2.606-.056 0v.735l3.364 1.87h1.324Zm3.93 0L11.92.735V0L7.233 2.606H8.56Z"
                    />
                </g>
                <defs>
                    <clipPath id="a">
                        <path fill="#fff" d="M0 0h24v16H0z" />
                    </clipPath>
                </defs>
            </svg>
        );
    return <IconFlagAU size="1.5rem" {...props} />;
}

export function IconNewZealand(props) {
    const {navigationV2} = useFeatureFlags();
    if (navigationV2)
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                width="24"
                viewBox="0 0 24 16"
                data-testid="flag-nz"
                {...props}
            >
                <g clipPath="url(#a)">
                    <path fill="#0052B4" d="M0 0h24v16H0V0Z" />
                    <path
                        fill="#D80027"
                        d="m19.936 6.957.159.489h.514l-.416.302.159.488-.416-.302-.416.302.159-.488-.416-.302h.514l.159-.49Zm-1.837 3.519.238.733h.77l-.623.453.238.733-.623-.453-.624.453.238-.733-.623-.453h.77l.239-.733Zm.07-5.759.198.611h.642l-.52.378.199.611-.52-.378-.52.378.199-.611-.52-.378h.643l.198-.61ZM16.4 6.936l.239.733h.77l-.623.453.238.733-.624-.453-.623.453.238-.733-.624-.453h.771l.238-.733Z"
                    />
                    <path
                        fill="#fff"
                        d="M12 0v1.432L9.883 2.608H12v2.783H9.23L12 6.93v1.068h-1.25L7.304 6.086v1.913H4.696V5.723L.598 7.999H0V6.567l2.118-1.176H0V2.608h2.77L0 1.07V0h1.25l3.446 1.915V0h2.609v2.276L11.403 0H12Z"
                    />
                    <path
                        fill="#D80027"
                        d="M6.75 0h-1.5v3.25H0v1.5h5.25V8h1.5V4.75H12v-1.5H6.75V0Z"
                    />
                    <path fill="#0052B4" d="M7.304 5.391 12 8v-.738l-3.368-1.87H7.304Z" />
                    <path fill="#fff" d="M7.304 5.391 12 8v-.738l-3.368-1.87H7.304Z" />
                    <path
                        fill="#D80027"
                        d="M7.304 5.391 12 8v-.738l-3.368-1.87H7.304Zm-3.936 0L0 7.262V8L4.696 5.39H3.368Z"
                    />
                    <path fill="#0052B4" d="M4.696 2.608 0 0v.737l3.368 1.871h1.328Z" />
                    <path fill="#fff" d="M4.696 2.608 0 0v.737l3.368 1.871h1.328Z" />
                    <path
                        fill="#D80027"
                        d="M4.696 2.608 0 0v.737l3.368 1.871h1.328Zm3.936 0L12 .738V0L7.304 2.608h1.328Z"
                    />
                </g>
                <defs>
                    <clipPath id="a">
                        <path fill="#fff" d="M0 0h24v16H0z" />
                    </clipPath>
                </defs>
            </svg>
        );
    return <IconFlagNZ size="1.5rem" {...props} />;
}
