import {Text, Paper, Button} from 'bigdatr-style';
import {ErrorBoundary, useTheme, usePrevious, Clickable} from 'bigdatr-style';
import {IconCross} from 'bigdatr-style/icon';
import {Grid, Box, FlexColumn, Fixed, Flex, Wrapper} from 'bigdatr-style/layout';
import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import AuthenticatedNavigation from './AuthenticatedNavigation';
import {useRoutes} from './Router';
import useSidebarState, {closedNavWidth, openNavWidth} from '~/util/useNavigationState';
import {RequestNzTrialButton} from '~/feature/notifications/news/articles/NzArrived';
import {useGlobalState} from './GlobalState';
import useFeatureFlags from '~/util/useFeatureFlags';
import AppHeader from './AppHeader';

type Props = {
    children: React.ReactNode;
    hideSidebar: boolean;
    showNavLinks: boolean;
    changeTeam: (teamId: string) => void;
};

export default function AppLayout(props: Props) {
    const {children, hideSidebar, showNavLinks, changeTeam} = props;
    const {colors} = useTheme();
    const {pathname} = useLocation();
    const {navOpen, setNavOpen} = useSidebarState();
    const routes = useRoutes();
    const {setShowNzBottomBanner, setShowNzTopTeaser, showNzBottomBanner, showNzTopTeaser} =
        useGlobalState().banners;
    const {navigationV2} = useFeatureFlags();

    const prevPathname = usePrevious(pathname);
    useEffect(() => {
        prevPathname === '/welcome/follow' && setNavOpen(true);
    }, [pathname]);

    if (hideSidebar) return <>{children}</>;

    // don't show banners on these routes
    const shouldShowBanner = [
        '/welcome', // all our onboarding routes are prefixed with 'welcome'
        routes.noPermissions.to(),
        routes.trialExpired.to(),
        '/creative' // creative item page
    ].every((path) => !pathname.startsWith(path));

    const showBottomBanner = showNzBottomBanner && shouldShowBanner;

    const showTopBanner = showNzTopTeaser && shouldShowBanner;

    const topOffset = '3rem';
    return (
        <Grid
            height="100vh"
            gridTemplateColumns={{
                md: `${closedNavWidth} auto`,
                xl: `${navOpen ? openNavWidth : closedNavWidth} auto`
            }}
            gridTemplateRows={{_: '4rem auto', md: 'unset'}}
        >
            {showTopBanner && (
                <TopBannerWrapper>
                    <NewNzLiveAnnouncementBanner />
                </TopBannerWrapper>
            )}
            {showBottomBanner && (
                <NewNzBottomBanner
                    onClose={() => {
                        setShowNzBottomBanner(false);
                        setShowNzTopTeaser(true);
                    }}
                    onSubmit={() => {
                        setShowNzBottomBanner(false);
                    }}
                />
            )}
            {/* This surrounding box is needed because we need 2 static children for the grid layout */}
            <Box minWidth={closedNavWidth} data-testid="AppLayout_sidebar">
                <Box
                    boxShadow={{_: navOpen ? '-4px 0 10px 0 #000' : 'none', xl: 'none'}}
                    position={{_: navOpen ? 'fixed' : 'static', md: 'fixed'}}
                    width={{_: '100%', md: navOpen ? openNavWidth : closedNavWidth}}
                    height="100%"
                    pt={showTopBanner ? topOffset : undefined}
                    top={0}
                    bottom={0}
                    left={0}
                    zIndex="Navigation"
                    background={colors.background}
                >
                    <AuthenticatedNavigation
                        changeTeam={changeTeam}
                        navOpen={navOpen}
                        toggleNav={() => setNavOpen(!navOpen)}
                        showLinks={showNavLinks}
                    />
                </Box>
            </Box>
            <Box height="100%" pt={showTopBanner ? topOffset : undefined}>
                {navigationV2 && <AppHeader />}
                <ErrorBoundary resetKeys={[pathname]}>{children}</ErrorBoundary>
            </Box>
        </Grid>
    );
}

function TopBannerWrapper(props: {children: React.ReactNode}) {
    return (
        <Fixed
            top={0}
            left={0}
            right={0}
            zIndex="Banner"
            backgroundColor="brand"
            color="white"
            p={3}
            gap={2}
            textAlign="center"
        >
            {props.children}
        </Fixed>
    );
}

function NewNzLiveAnnouncementBanner() {
    const routes = useRoutes();
    return (
        <span>
            LIVE NOW&mdash;
            <Clickable
                style={{textDecoration: 'underline'}}
                onClick={() => routes.notificationNews.push({})}
            >
                Unlock access to New Zealand Digital Advertising Creative for 5 days
            </Clickable>
        </span>
    );
}

function NewNzBottomBanner(props: {onClose: () => void; onSubmit: () => void}) {
    return (
        <Fixed zIndex="Banner" inset="0" backgroundColor="overlay" data-testid="fullscreen-banner">
            <FlexColumn height="100%" justifyContent="flex-end">
                <Paper shadow>
                    <Wrapper maxWidth="40rem">
                        <FlexColumn gap={4} py={5}>
                            <Flex gap={4}>
                                <Text textStyle="heading1">
                                    Be the first to explore digital channels in New Zealand!
                                </Text>

                                <Clickable onClick={props.onClose}>
                                    <Box
                                        p={2}
                                        outline="outline"
                                        borderRadius="10rem"
                                        data-testid="dismiss-fullscreen-banner"
                                    >
                                        <IconCross size="3rem" />
                                    </Box>
                                </Clickable>
                            </Flex>

                            <span>
                                It&apos;s official&mdash;New Zealand digital creative data is now
                                live and we&apos;ve given your team free access for 5 days. Click
                                below and start monitoring what competitors are offering and
                                communicating to everyday Kiwis!
                            </span>

                            <Flex gap={3}>
                                <RequestNzTrialButton onSubmit={props.onSubmit} />
                                <Button secondary onClick={props.onClose}>
                                    No thanks
                                </Button>
                            </Flex>
                        </FlexColumn>
                    </Wrapper>
                </Paper>
            </FlexColumn>
        </Fixed>
    );
}
