import {Button, MenuItem, MoreMenu, Tooltip, useAnalytics, useAuth, useViewer} from 'bigdatr-style';
import {Input} from 'bigdatr-style';
import {Box, Flex, FlexColumn, Relative, Wrapper} from 'bigdatr-style/layout';
import {AU} from 'bigdatr-style/src/team/Team';
import React from 'react';
import {Inbox, QuestionCircle, Settings, User2} from 'react-swm-icon-pack';
import DropdownSwitchTeams from '~/affordance/DropdownSwitchTeam';
import {useCountry} from '~/feature/country/CountryContext';
import CountrySwitch from '~/feature/country/CountrySwitch';
import {HELP_DOC_LINKS, NotificationBadge} from './AuthenticatedNavigation';
import {useGlobalState} from './GlobalState';
import {useRoutes} from './Router';

export default function AppHeader() {
    const viewer = useViewer();
    const {changeTeam} = useAuth();
    const {news, headerSearch} = useGlobalState();
    const {hasUnreadNews} = news;
    const {setDebouncedSearch} = headerSearch;
    const {country} = useCountry();
    const routes = useRoutes();
    const analytics = useAnalytics();

    return (
        <Box borderBottom="outline">
            <Wrapper
                maxWidth="120rem"
                px={3}
                py={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap={3}
            >
                {country === AU ? (
                    <Box width={{lg: '20rem'}}>
                        <Input
                            type="search"
                            onDebounce={setDebouncedSearch}
                            debounce={500}
                            onClick={() => routes.search.push({})}
                            clearable
                            placeholder="Search advertisers"
                        />
                    </Box>
                ) : (
                    <div />
                )}

                <Flex gap={2} alignItems="center">
                    {viewer.currentTeam.hasMoreThanOneCountry && (
                        <Tooltip content="Change country" offset={12}>
                            <CountrySwitch showText />
                        </Tooltip>
                    )}
                    <Tooltip content="Settings" offset={12}>
                        <Button thin tertiary to="/settings">
                            <Settings />
                        </Button>
                    </Tooltip>
                    <Tooltip content="Help" offset={12}>
                        <Button thin tertiary href={HELP_DOC_LINKS[country]} target="_blank">
                            <QuestionCircle />
                        </Button>
                    </Tooltip>
                    <Tooltip
                        content={hasUnreadNews ? 'You have 1 new message' : 'News'}
                        alwaysVisible={hasUnreadNews}
                        offset={12}
                    >
                        <Button thin tertiary to={routes.notificationNews.to({})}>
                            <Relative>
                                <Inbox />
                                {hasUnreadNews && <NotificationBadge />}
                            </Relative>
                        </Button>
                    </Tooltip>

                    <MoreMenu
                        icon={
                            <Flex alignItems="center" gap={2}>
                                <User2 /> {viewer.currentTeam.name}
                            </Flex>
                        }
                        floaterStyles={{maxWidth: '15rem', hyphens: 'auto'}}
                    >
                        {() => {
                            return (
                                <>
                                    <DropdownSwitchTeams viewer={viewer} changeTeam={changeTeam} />

                                    <FlexColumn py={2}>
                                        <MenuItem small onClick={() => analytics.chat()}>
                                            Contact Us
                                        </MenuItem>
                                        <MenuItem small href="https://bigdatr.com/licenseagreement">
                                            License Agreement
                                        </MenuItem>
                                        <MenuItem small href="https://bigdatr.com/terms-of-use">
                                            Terms of Use
                                        </MenuItem>
                                        <MenuItem small href="https://bigdatr.com/privacy-policy">
                                            Privacy Policy
                                        </MenuItem>
                                        <MenuItem small to="/logout">
                                            Logout
                                        </MenuItem>
                                    </FlexColumn>
                                </>
                            );
                        }}
                    </MoreMenu>
                </Flex>
            </Wrapper>
        </Box>
    );
}
