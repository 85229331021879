import * as SegmentApi from './segment';
import * as ViewerApi from './viewer';
import * as FilterApi from './filter';
import * as SearchApi from './search';
import * as CreativeApi from './creative';

const rqApi = {
    ...CreativeApi,
    ...FilterApi,
    ...ViewerApi,
    ...SegmentApi,
    ...SearchApi
};

export default rqApi;
