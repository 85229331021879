import {skipToken, useInfiniteQuery, useQuery} from '@tanstack/react-query';
import {Creative} from 'bigdatr-style';
import {CreativeListOrderBy} from '~/creative/affordance/CreativeListSortBy';
import {useCountry} from '~/feature/country/CountryContext';
import {QueryStringFilters} from '~/feature/filter/types';
import useClientMainAuthorizedFetch from '../clientMainAuthorisedFetch';
import {CreativeList, PublicCreativeItem} from './graphqlQueries';

export function useSingleCreative(args: {id: string}, options?: {enabled: boolean}) {
    const {id} = args;
    const request = useClientMainAuthorizedFetch();
    const enabled = options?.enabled ?? true;
    return useQuery({
        queryKey: ['singleCreative', id],
        queryFn: enabled
            ? async () => {
                  const response = await request(PublicCreativeItem, {creativeId: id});
                  const rawCreative = response.creativeV2?.publicCreativeItemV2;
                  if (!rawCreative) throw new Error('Creative not returned from api');

                  return new Creative(rawCreative);
              }
            : skipToken
    });
}

export function useInfiniteCreativeList(
    args: {filters?: QueryStringFilters; orderBy?: CreativeListOrderBy; pageNumber?: number},
    options?: {enabled: boolean}
) {
    const {country} = useCountry();
    const {orderBy, pageNumber} = args;
    const pageSize = 20;
    const initialPageNumber = pageNumber ?? 1;

    const {creativeDateRange, ...otherFilters} = args.filters ?? {};
    const filters = {...otherFilters, country, dateRangeFilter: creativeDateRange};

    const request = useClientMainAuthorizedFetch();
    const enabled = options?.enabled ?? true;
    return useInfiniteQuery({
        queryKey: ['creativeList', filters, orderBy, pageSize],
        queryFn: enabled
            ? async ({pageParam}) => {
                  const response = await request(CreativeList, {
                      input: {
                          filters,
                          orderBy,
                          pagination: {
                              count: pageSize,
                              page: pageParam
                          }
                      }
                  });
                  const rawResponse = response.creativeV2?.creativeListV2;
                  if (!rawResponse) throw new Error('Creative list not returned from api');

                  const {items, totalCount, paginationInfo} = rawResponse;

                  return {
                      totalCount,
                      paginationInfo,
                      items: items.map((rawCreative) => new Creative(rawCreative))
                  };
              }
            : skipToken,
        initialPageParam: initialPageNumber,
        getNextPageParam: (lastPage) => {
            const hasNextPage = lastPage.paginationInfo?.hasNextPage;
            const currentPage = lastPage.paginationInfo?.currentPage;
            if (hasNextPage && currentPage) return currentPage + 1;
            return null;
        },
        getPreviousPageParam: (lastPage) => {
            const hasPreviousPage = lastPage.paginationInfo?.hasPreviousPage;
            const currentPage = lastPage.paginationInfo?.currentPage;
            // dont allow going to page 0
            if (hasPreviousPage && currentPage) return currentPage - 1;
            return null;
        }
    });
}
